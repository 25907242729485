import React from 'react';
import styled from 'styled-components';
import {
  FundsLogo,
  PolandLogo,
  PrimaryIcon,
  RegionLogo,
  SEO,
  UkraineFlag,
  UnionLogo,
} from '../components';
import Layout from '../components/Layout/Layout';
import { rem, theme } from '../theme';

const AboutSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  padding: 48px 32px;

  & h3 {
    color: ${theme.colors.font.secondary};
    margin: 0;
  }

  & h2 {
    text-align: center;
    margin-bottom: 0;
    margin-top: 8px;
    max-width: 1000px;
  }

  & p:first-of-type {
    margin-top: 8px;
    text-align: center;
  }

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 48px 148px;
  }

  ${PrimaryIcon} {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  font-size: ${rem(2)};
  text-align: justify;
  padding-top: 10px;
  padding-bottom: 10px;
  & > a {
    color: ${theme.colors.topbar.link_active_button} !important;
    text-decoration: none !important;
    font-size: ${rem(3)};
    display: contents;
  }
  & > b {
    display: contents;
  }
  margin: 0px;
`;

const WhiteBox = styled.div`
  background-color: white;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  flex-wrap: wrap;

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 24px 148px;
  }

  & > .gatsby-image-wrapper {
    margin: 16px;
  }
`;

const FlagSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  & > span {
    margin-left: 8px;
    color: #005bbb;
    font-size: ${rem(4)};
    font-weight: ${theme.fontWeights[700]};
  }

  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    display: none;
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <FlagSection>
        <UkraineFlag />
        <span>We stand with Ukraine</span>
      </FlagSection>
      <WhiteBox>
        <FundsLogo />
        <PolandLogo />
        <RegionLogo />
        <UnionLogo />
      </WhiteBox>
      <AboutSection>
        <h3>Project title</h3>
        <h2>
          Portable broadband jamming device for radiocommunication signals based
          on a digital signal processing path and fast RF transceivers and FPGA
          unit
        </h2>
        <p>Number: POIR.01.01.01-00-0849/20</p>
        <p style={{ textAlign: 'center' }}>
          Project implementation period: 01.01.2022-30.11.2023
          <br />
          Project value: PLN 6,878,039.02
          <br />
          Funding value: PLN 3,866,612.93
          <br />
        </p>

        <TextColumn>
          Main goal of the project is development of a{' '}
          <b>
            portable radio jammer device operating in a wide spectrum of
            telecommunications bands, using various jamming algorithms and
            advanced energy management methods.
          </b>{' '}
          The project includes the design and creation of intellectual property
          blocks focusing on analysis and generation of jamming signals for
          implementation in FPGA devices with application of additional RF
          signal synchronization methods. As part of the project, it is planned
          to{' '}
          <b>
            develop an algorithm for optimizing energy usage and prediction of
            working time
          </b>
          , including, among others, energy losses in the transmission path,
          jamming on unused bands or with excessive power, and energy losses
          related to software operation.
        </TextColumn>
        <TextColumn>
          The system will be managed using monitoring and control software
          consisting of two components:{' '}
          <b>embedded software running on microcontrollers</b> focusing on
          communication, analysis and data acquisition from modules of jamming
          device and <b>management software running on a PC</b> used for
          visualization of device parameters, analyzing its work and uploading
          the prepared jamming action profiles. It is also planned to design the{' '}
          <b>jamming device housing</b> focusing on optimal efficiency in the
          areas of environmental, mechanical, electromagnetic and thermal
          parameters.
        </TextColumn>
      </AboutSection>
    </Layout>
  );
};

export default IndexPage;
